

import SearchBar from "./components/SearchBar";
import "./styles.css"
import DonorData from './donors_data.json';
import slides from './slider_data.json';
import React, { useState, useRef } from "react";
// import DonorDetail from "./components/DonorDetail";
import Location from "./components/Location";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MarianGarden from "./pages/MarianGarden";
import Welcome from "./pages/Welcome";
import Footer from './components/Footer';
// import Navbar from './components/Navbarofapp';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCube } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cube';
import qrcode from '../src/OLLV Donate-QR_Code.jpg';
import ollvLogo from './OLLVLogo.png';
import DonorMap from "./components/DonorMap";
import "./fonts/Helvetica-Neue-Bold.ttf";
import "./fonts/Helvetica-Neue-Light.ttf";
import "./fonts/Neutraface-Display-Medium.ttf";


function App() {

  const handleDatafromSearch = (specdonordata)=>{
    // console.log('Do a search with ',specdonordata);
    setDonor(specdonordata);
  }
  const [donor, setDonor] = useState([])
  console.log('My Donor',donor)

  const defaultImageName = 'lavang.jpg';
const imageName = donor.pane_image_name === undefined || donor.pane_image_name === null  ? defaultImageName : donor.pane_image_name
console.log('My imageName',imageName)





const [showSearchBar, setShowSearchBar] = useState(false); 

const [toggleAutoPlay, setToggleAutoPlay] = useState(false);



const toggleSearchMenu = () => {
setShowSearchBar(!showSearchBar); 
setToggleAutoPlay(!toggleAutoPlay)
toggleAutoPlay ?  swiperRef.current.autoplay.start() :  swiperRef.current.autoplay.stop() 
// console.log('Auto Play stopped');
}

console.log(toggleAutoPlay);

const swiperRef = useRef(null);

// const gotoHome = () => {
//   window.location.href = "/";
// }


  return (
    <Router>
    <div className="App">
    {/*start of Navbar */}
 {/*   <Navbar /> */}
    {/*end of Navbar */}

    {
      !donor.location 
      ? 
   
     <Switch>
      <Route exact  path="/">
  {/**Swiper SLide here */}
      <Swiper
      modules={[EffectCube,Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      scrollbar={{ enabled: false }} // Disable the scrollbar
      
      // navigation
      autoplay={{
        delay: 9000,
        disableOnInteraction: false,
      }}

      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}


      pagination={{ clickable: true }}
      // allowTouchMove={false} // Disable swipe to change slides
      // scrollbar={{ draggable: false }}
     
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      effect={'cube'}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
      
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
    >
    {slides.map((slide) => (
      <div className='swiper-slide'>
   
      <SwiperSlide key={slide.id}>
      <div className="slide-title">
      <h1>{slide.title}</h1> 
      </div>
      
      <Link to="/welcome">
        <img src={slide.image} alt={slide.alt_image} />
      </Link>
          <div className='slide-content'>
        
        
        {showSearchBar && (

          <div className="menu">
    
            {/* Menu content here */}
    
            {
              <SearchBar  placeholder='Enter Donor name or last 4 digits of phone' data={DonorData} passedData={handleDatafromSearch} />
             
            }
    
          </div>
    
        )}
          </div>
          <div className='slide-footer-right'>
          <div onClick={toggleSearchMenu}>
          <div class="wrapper">
          { /*<label><i class='icon-off'><SearchIcon /></i></label> */ }
            <label><i class='icon-off'>
            {!toggleAutoPlay ?   <svg 
              aria-hidden="true"
              viewBox="0 0 24 24" width="50" height="50"
            >
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg> : 
        
            <svg width="50" height="50" viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z" />
          </svg>
   
          }
          
            </i></label>
        </div>
         
          { /**  <SearchIcon />*/ }
          
          
      
          </div>
          </div>
          <div className="slide-footer-left">
           {
             slide.touch_me ? <Link to="/welcome"><img style= {{ display:'block', margin: '0 auto',height:200 , width:200}} src={slide.touch_me} alt="Touch Me" /></Link> : <p style={{height:200}}>&nbsp;</p>
           } 
        
          <div className="footer-spacing">
           <p>TO DONATE:</p>
           <img style= {{ display:'block', margin: '0 auto',height:300 , width:300}}  src={qrcode} alt="Donate QR Code" class="src" />
             <p>ZELLE:(657) 324-8277</p>
         </div>
          </div>     
        
      </SwiperSlide>
      </div>
  ))}
 
  
      </Swiper>
      {/**Swiper SLide here */}
      
      </Route> 
      <Route path="/garden">
      <MarianGarden /> 
      </Route> 
      <Route path="/welcome">
      <Welcome /> 
      </Route> 
      <Route path="/map">
      <DonorMap donordatadetails={donor} /> 
      </Route> 
     </Switch>
   
     :
    //  {/*beginning of map */}
  <Link to='/'><DonorMap donordatadetails={donor} /></Link>
   

// end of map
}
       
<Footer/>   

</div>
</Router>

  );
}

export default App;
