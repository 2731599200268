import React from 'react'
import ollvLogo from '../OLLVLogo.png';
import Location from './Location';
import qrcode from '../OLLV Donate-QR_Code.jpg';
// import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";

function DonorMap({donordatadetails}) {

    const toDate = () => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
      
        const currentDate = new Date();
        const date = `${days[currentDate.getDay()]} ${currentDate.getDate()} ${months[currentDate.getMonth()]
          }`;
        return date;
      };

    //   const history = useHistory();
      function gotoHome() {
    //    history.push("/welcome");
         window.location.href = "/";
      }

  return (
    <div>{/*beginning div */}
    <div className="flex-container">
       <div className="column-left-omi">
       <br />
       <img src={ollvLogo} className="logo" alt="RCBO Logo"/>
       <div className="rotated">OLLV SHRINE</div>
      
       </div>
     <div className="column-right-omi">
     {
       <Location toDate={toDate} donordatadetails={donordatadetails} />

   }
       
 
     </div>
    </div>

    <div className='slide-footer-right'>
    <div onClick={gotoHome}>
    <div class="wrapper">
    { /*<label><i class='icon-off'><SearchIcon /></i></label> */ }
      <label><i class='icon-off'>

  
      <svg width="50" height="50" viewBox="0 0 24 24">
      <path d="M8 5v14l11-7z" />
    </svg>


    
      </i></label>
  </div>

    </div>
    </div>
    <div className="slide-footer-left">
    
   </div>
    <div>
   

</div>
 {/*ending div */}
</div>
  )
}

export default DonorMap