import React from 'react'

function Footer() {
  return (
    
    <div className="footer">
       <h2>For Kiosk hardware or software problems, call IT Department at (714) 282-3019</h2>
   </div>  
  
    
  )
}

export default Footer