import React from 'react';
import { useHistory } from 'react-router';
import qrcode from '../OLLV Donate-QR_Code.jpg';
import { Link } from 'react-router-dom';

// import { Link } from 'react-router-dom';

// const handleDivClick= () => {         

//     window.location.href = "/";
   
//     }

function Welcome() {
    const history = useHistory()

    // redirect to home after 18 secs
    setTimeout(() => {
    //   history.push('/')
    window.location.href = "/";
    }, 15000)


    const gotoHome = () => {
        window.location.href = "/";
    }
  

  return (
    // <Link to="/">
      <div className="s-pagewrap">
    
          <header className="s-header">
              <div className="s-header_content">
  
                  <h1>  The Our Lady of La Vang Shrine
                      Information Kiosk
                  </h1>
  
              </div> 
          </header> 

       
  
  
          <section id="intro" className="s-intro">
  
              <div className="s-intro__bg"></div>
  
              <div className="row s-intro__content">
                  <div className="column lg-12">
                      
                      <div>
                  
                   
                       
      <div className="card">
          <div className="header">
              <p>Learn</p>
          </div>
              <div className="containerCard">
                  <ul>
                      <li className="na"><span>Our Lady of La Vang Shrine</span></li>
                      <li className="na"><span>Marian Gardens</span></li>
                      <li className="na"> <span>Garden of Gethsemane</span></li>
                      <li className="na"> <span>Martyr Walls</span></li>
              </ul>
             
          </div>
      </div>
  
      <div className="card">
          <div className="header">
              <p>Search</p>
          </div>
              <div className="containerCard">
                  <ul>
                  <li className="na"><span>Engraved Names</span></li>
                  <li className="na"> <span>Sponsored Items</span></li>
              </ul>
             
          </div>
      </div>
  
      
          </div>
       
       
          
      </div>
  
               <div className="row contact_section">
                  <div>
                  <h2>Contact the OLLV Foundation for Information on how to become a donor, to sponsor or to be featured on the memorial or donor wall.</h2>
                  
                                    <p>Phone: (714) 282-4290</p>
                                    <p>Email: Info@ollvfoundation.org</p>
                                    <p>Website: OLLVFoundation.org</p>
                              
      
                
                  </div>
              </div> 
  
  
              </div> 
          </section>
  
  
          <section id="info" className="s-info">
  
              <div className="s-info__bg"></div>
  
              <div className="row">
                  <div className="column lg-12">
  
               
                  </div>
              </div>
  
          </section>

          <div className='slide-footer-right'>
          <div onClick={gotoHome}>
          <div class="wrapper">
         
            <label><i class='icon-off'>
                   
            <svg width="50" height="50" viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z" />
          </svg>
   
        
          
            </i></label>
        </div>
         
          { /**  <SearchIcon />*/ }
          
          
      
          </div>
          </div>


          <div className='slide-footer-left'>
            
          <p>TO DONATE:</p>
          <img style= {{ display:'block', margin: '0 auto',height:300 , width:300}}  src={qrcode} alt="Donate QR Code" class="src" />
          <p>ZELLE:(657) 324-8277</p>
        { /*  <p>ollvfoundation.org/donate</p>*/} 
          
          </div>

          <div className="kiosk-footer">
          <h2>For Kiosk hardware or software problems, call IT Department at (714) 282-3019</h2>
          </div>
  
      </div>
    //   </Link>
  )
}

export default Welcome